import { useUserStore } from '@/store/user';
import { setRedirectPath } from '@/utils/auth';
import { sendAnatylics } from '@/utils/analytics';

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    authRequired,
    skipAuth,
    getFreeAccess = false,
    successRedirectUrl,
    authPage = 'login',
  } = to.meta;

  if (skipAuth) {
    return;
  }

  const userStore = useUserStore();
  const { $auth0 } = useNuxtApp();
  const isAuthenticated = await $auth0.isAuthenticated();

  if (!isAuthenticated || !userStore.isReady || !userStore.token) {
    try {
      const token = await $auth0.getTokenSilently();
      userStore.saveTokenData(token);
      await userStore.getUser();

      if (getFreeAccess) {
        const url = to.fullPath.replace('/gutten-test', '');
        return navigateTo(url);
      } else if (successRedirectUrl) {
        return navigateTo(successRedirectUrl);
      } else {
        return;
      }
    } catch (error) {
      if (!authRequired) {
        return;
      }
      // If the page requires authentication, redirect to the login page and then to the requested page
      userStore.cleanupUserData();

      const url = getFreeAccess
        ? to.fullPath.replace('/gutten-test', '')
        : successRedirectUrl || to.fullPath;

      sendAnatylics('login', {
        user_id: 'not authorised',
        redirect: url,
      });

      setRedirectPath(url);

      return navigateTo(
        `/auth?auth_screen_hint=${authPage}${
          getFreeAccess ? '&auth_free_access=true' : ''
        }`,
        {
          redirectCode: 301,
        }
      );
    }
  }
});
