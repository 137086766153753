import { LOCAL_STORAGE_KEY } from '@/utils/constants';

export const setRedirectPath = (path) => {
  cleanupRedirectPath();
  if (!path) {
    return;
  }
  localStorage.setItem(LOCAL_STORAGE_KEY.REDIRECT_PATH, path);
};

export const cleanupRedirectPath = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.REDIRECT_PATH);
};

export const getRedirectPath = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.REDIRECT_PATH);
};

export const setRestrictedUserEmail = (email) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL, email);
};

export const getRestrictedUserEmail = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL);
};

export const cleanupRestrictedUserEmail = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL);
};
