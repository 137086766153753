import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
import { API_METHOD } from '@/utils/constants';

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      user: null,
      token: null,
      tokenExpirationTime: null,
      isReady: false,
      controller: null,
    };
  },

  actions: {
    async getUser() {
      if (this.controller?.abort) {
        this.controller.abort();
      }

      this.controller = new AbortController();

      const { data } = await apiFetch('users/me', {
        method: API_METHOD.GET,
        signal: this.controller.signal,
        customOptions: {
          handleLocalError: true,
        },
      });

      if (data.value.response) {
        this.user = data.value.response;
      }

      this.isReady = true;
      return data.value.response;
    },

    saveTokenData(token) {
      if (!token) return;
      const decodedToken = jwtDecode(token);
      this.token = token;
      this.tokenExpirationTime = decodedToken.exp * 1000;
    },

    cleanupUserData() {
      this.user = null;
      this.token = null;
      this.tokenExpirationTime = null;
    },

    async updateUser(payload) {
      const { data } = await apiFetch('users/me', {
        method: API_METHOD.PATCH,
        body: payload,
      });

      if (data.value.response) {
        this.user = data.value.response;
      }
    },

    async subscribeToUpdates(email) {
      const { error } = await apiFetch('users/subscribe', {
        method: API_METHOD.POST,
        body: {
          email: email.toLowerCase(),
        },
      });

      return error.value;
    },

    isTokenExpired() {
      if (!this.tokenExpirationTime) return true;
      // Token is considered expired 5 minutes before its actual expiration time
      return new Date(this.tokenExpirationTime - 5 * 60 * 1000) < new Date();
    },

    updateReaderSettings(settings) {
      if (!this.user?.reader_settings) return;

      this.user = {
        ...this.user,
        reader_settings: {
          ...this.user.reader_settings,
          ...settings,
        },
      };

      this.updateUser({
        reader_settings: this.user.reader_settings,
      });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    readerSettings: (state) => state.user?.reader_settings || {},
    readerSettingsSelectedTheme: (state) => state.user?.reader_settings?.theme,
    readerSettingsSelectedFont: (state) => state.user?.reader_settings?.font,
    readerSettingsSelectedFontSize: (state) =>
      state.user?.reader_settings?.font_size,
    readerSettingsSelectedPageLayout: (state) =>
      state.user?.reader_settings?.page_layout,
    readerSettingsSelectedNavigation: (state) =>
      state.user?.reader_settings?.navigation,
  },
});
