import { Auth0Client } from '@auth0/auth0-spa-js';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const auth0 = new Auth0Client({
    domain: config.public.authDomain,
    clientId: config.public.authClientId,
    authorizationParams: {
      redirect_uri: `${config.public.baseUrl}auth0/callback`,
      audience: config.public.auth0Audience,
      scope: 'openid profile email offline_access',
    },
  });

  return {
    provide: {
      auth0,
    },
  };
});
