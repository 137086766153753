<template>
  <button
    :class="[buttonClass, { 'no-hover': isTouchDevice }]"
    :disabled="disabled"
  >
    <svg
      v-if="props.loading"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        class="spinner"
      />
    </svg>
    <component :is="props.leftIcon" v-if="!props.loading" class="icon-left" />
    <span v-if="!props.loading">
      <slot />
    </span>
    <component :is="props.rightIcon" v-if="!props.loading" class="icon-right" />
  </button>
</template>

<script setup>
import { computed } from 'vue';
import { cva } from 'class-variance-authority';
import { isTouchSupportDevice } from '@/utils/devices';

const props = defineProps({
  /**
   * show icon left
   * @type {object}
   */
  leftIcon: { type: Object, required: false, default: () => {} },
  /**
   * show icon right
   * @type {object}
   */
  rightIcon: { type: Object, required: false, default: () => {} },
  /**
   * loading button
   * @type {boolean}
   */
  loading: { type: Boolean },
  /**
   * disabled button
   * @type {boolean}
   */
  disabled: { type: Boolean, default: false },
  /**
   * theme button
   * @type {string}
   */
  variant: {
    type: String,
    validator: (val) =>
      ['primary', 'secondary', 'danger', 'text'].includes(val),
    default: 'primary',
  },
  /**
   * size button
   * @type {string}
   */
  size: {
    type: String,
    validator: (val) => ['default', 'md', 'sm', 'icon'].includes(val),
    default: 'default',
  },
});

const isTouchDevice = isTouchSupportDevice();

const buttonClass = computed(() => {
  return cva('btn', {
    variants: {
      variant: {
        primary: '',
        secondary: 'secondary',
        danger: 'danger',
        text: 'text',
      },
      size: {
        default: '',
        md: 'btn-md',
        sm: 'btn-sm',
        icon: 'btn-icon',
      },
      disabled: {
        true: 'btn-disabled',
      },
    },
  })({
    variant: props.variant,
    size: props.size,
    disabled: props.disabled,
  });
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
