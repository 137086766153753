<template>
  <div
    v-if="isClient && (status === 'not-found' || status === 'server-error')"
    class="layout-error"
    :class="{ server: status === 'server-error' }"
  >
    <MainHeader />
    <div class="container">
      <div class="error-wrapper">
        <div class="error-img">
          <img
            v-if="status === 'not-found'"
            src="/image/not-found.svg"
            alt=""
            width="406"
            height="250"
          />
          <img
            v-else-if="status === 'server-error'"
            src="/image/error.svg"
            alt=""
            width="204"
            height="243"
          />
        </div>
        <h2 class="error-title">
          {{
            status === 'not-found'
              ? 'We’ve lost this page'
              : 'Internal server error'
          }}
        </h2>
        <p class="error-subtitle first">
          {{
            status === 'not-found'
              ? 'Sorry, the page you are looking for'
              : "We're experiencing an internal server problem."
          }}
        </p>
        <p class="error-subtitle last">
          {{
            status === 'not-found'
              ? 'doesn’t exist or has been moved.'
              : 'Please try again later.'
          }}
        </p>
        <AppButton
          v-if="status === 'not-found'"
          class="error-btn"
          @click="router.push({ path: '/' })"
        >
          Back to Main Page
        </AppButton>
        <div v-if="status === 'not-found'" class="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="79"
            viewBox="0 0 31 79"
            fill="none"
          >
            <path
              d="M24.5724 0.687381C24.5565 0.690958 24.5407 0.69493 24.5244 0.699398C24.2713 0.768996 24.0823 0.931016 23.9089 1.08506C19.5584 4.94088 14.6212 9.51711 11.1472 13.7429C0.636198 26.527 -2.32185 42.0344 3.20639 54.7222C5.13682 59.152 8.20153 63.2449 12.2702 66.7195C14.2484 68.4091 16.6992 69.9752 19.2448 71.3261C21.7325 72.6459 24.7882 73.9665 27.4798 75.2736C27.5436 75.3048 27.5033 75.3856 27.4227 75.4098C26.4809 75.6911 22.4622 77.3888 22.1724 77.5364C20.87 78.1992 21.2091 79.0278 22.81 78.5407C24.6934 77.9674 26.1891 77.3753 27.9859 76.6893C28.5891 76.4593 29.919 76.0587 30.2852 75.646C30.7187 75.1572 30.4806 74.6148 30.401 74.0883C30.2231 72.9021 30.1231 71.5382 29.7173 70.3934C29.343 69.3382 27.6111 68.6042 27.9054 70.4034C28.0982 71.5806 28.3183 72.8949 28.6087 74.0623C28.6196 74.1049 28.548 74.1415 28.4999 74.1178C27.435 73.592 25.4397 72.7566 24.4291 72.3663C8.49722 66.214 0.767987 50.6221 2.50052 36.9688C2.83947 34.2961 3.46553 31.6111 4.37559 28.9459C7.69493 19.2255 16.495 9.40167 24.4632 2.45663C24.8524 2.11733 25.3488 1.79523 25.4549 1.38727C25.558 0.99089 25.1127 0.569847 24.5724 0.687381Z"
              fill="#2C67BF"
            />
          </svg>
        </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>

<script setup>
import AppButton from '@/components/ui/button/AppButton.vue';
import MainHeader from '@/components/layout/MainHeader.vue';
import MainFooter from '@/components/layout/MainFooter.vue';

definePageMeta({
  authRequired: false,
});

const router = useRouter();
const error = useError();
const isClient = ref(false);

const status = computed(() => {
  if (error.value?.statusCode === 404) {
    return 'not-found';
  } else if (error.value?.statusCode === 500) {
    return 'server-error';
  }
});

onMounted(() => {
  isClient.value = process.client;
});
</script>
