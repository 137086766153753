export default () => {
  if (process.client) {
    const config = useRuntimeConfig();

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${config.public.gtm}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    noscript.appendChild(iframe);

    document.body.insertBefore(noscript, document.body.firstChild);
  }
};
