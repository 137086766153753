export const isValidHexColor = (color) => {
  if (!color) {
    return false;
  }
  return /^#[0-9A-F]{6}(?:[0-9A-F]{2})?$/i.test(color);
};

export const trimString = (str, length) => {
  if (!str) {
    return '';
  }
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};
