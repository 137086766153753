<template>
  <NuxtLink v-if="userStore.isAuthenticated" to="/">
    <slot></slot>
  </NuxtLink>
  <a v-else href="/">
    <slot></slot>
  </a>
</template>

<script setup>
import { useUserStore } from '@/store/user';

const userStore = useUserStore();
</script>
